import React from "react"
import Fade from "react-reveal/Fade"
import { Parallax } from 'react-scroll-parallax'

const Header = () => {
  return (
    <div className="section" id="home">
      <Parallax speed={-25}>
        <div className="header-wrapper">
          <Fade bottom>
            <h1> Hey, I'm John Lodan Tojot  </h1>
            <p> SOFTWARE DEVELOPER </p>
            <span> Specialized in : ReactJs ⦿ ReactNative ⦿ NextJs ⦿ ExpressJs ⦿ Laravel ⦿ MongoDB ⦿ MySQL  </span>
          </Fade>
        </div>
      </Parallax>
    </div>
  )
}

export default Header
