import React from "react"
// import scrollTo from "gatsby-plugin-smoothscroll"
const Navbar = () => {
  return (
    <div className="section">
      <div className="navbar-wrapper">
        <a
          role="button"
          // onClick={() => scrollTo("#home")}
          className="name"
          href="#home"
          tabIndex={0}
        >
          <h1>Portfolio.</h1>
        </a>
        <div className="links-wrapper">
          <a data-scroll href="#about">About</a>
          <a data-scroll href="#skills">Skills</a>
          <a data-scroll href="#work">Projects</a>
          <a data-scroll href="#contact">Contact</a>
        </div>
      </div>
    </div>
  )
}

export default Navbar
