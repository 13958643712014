import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import data from "../../yourdata"
import { Image, Button, Modal, Row, Col } from 'antd'
import ImageGallery from "react-image-gallery";
import WorkModal from "./modal";
const Work = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({})

  function handleOpen(data) {
    setIsModalOpen(true)
    setSelectedData(data)
  }

  return (
    <div className="section" id="work">
      <div className="container">
        <div className="work-wrapper">
          <Fade bottom>
            <h1>Projects</h1>
          </Fade>

          <div className="grid">
            <Fade bottom cascade>
              {data.projects.map((project, index) => {
                return (
                  <div className="work-items" key={index} onClick={() => handleOpen(project)}>
                    <div className="wrapper">
                      <div className="img-container">
                        <img src={project.imageSrc} alt={project.title} />
                      </div>
                      <div>
                        <h1>{project.title}</h1>
                        <div className="platform">Platform:&nbsp; {
                          project.platform && project.platform.map((platform, index) => {
                            return <div key={index}><span>{platform}</span>&nbsp;</div>
                          })
                        }
                        </div>
                        <div className="role">Role:&nbsp; {
                          project.role && project.role.map((role, index) => {
                            return <div key={index}><span>{role}</span>&nbsp;</div>
                          })
                        }
                        </div>
                      </div>
                    </div>
                    <div className="work-details">
                      <p>{project.description}</p>
                      <div className="tech">
                        {project.tech && project.tech.map((tech, index) => {
                          return (
                            <div className="tech-item" key={index}>
                              <span>{tech}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )
              })}
            </Fade>
          </div>
        </div>
      </div>
      <WorkModal open={isModalOpen} onCancel={() => setIsModalOpen(false)} data={selectedData} />
    </div>
  )
}

export default Work
