import { Tabs, Collapse } from 'antd'

const { Panel } = Collapse;
const ContentTwo = () => {
    return (
        <div>
            <Collapse bordered={false} defaultActiveKey={['1', '2']} style={{ background: 'transparent', color: 'white' }}>
                <Panel header={<h1 style={{ fontSize: 14, margin: 0, textAlign: 'left', marginBottom: 2 }}>Cloudwalk Digital Inc / Universal Robina Corporation</h1>} key="1">
                    <p style={{ fontSize: 12, margin: 0, marginBottom: 1 }}>Developed an Order Management System, Users can manage website, view dashboard reports, upload their products using the management portal and receive an orders. We used ReactJS, Redux, PHP, Laravel.</p>
                    <Tabs defaultActiveKey={1} items={[
                        {
                            key: 1,
                            label: <div className="tab-item">
                                <p style={{ paddingLeft: 2, paddingRight: 2 }}>Responsibilities</p>
                            </div>,
                            children: <ul>
                                <li style={{ fontSize: 11 }}>Convert UI/UX prototype into actual code in WEB and MOBILE.</li>
                                <li style={{ fontSize: 11 }}>Make sure all the pages are responsive.</li>
                                <li style={{ fontSize: 11 }}>Producing, maintaining and modifying websites and user interfaces.</li>
                                <li style={{ fontSize: 11 }}>Fixing any website issues or bugs that arise.</li>
                                <li style={{ fontSize: 11 }}>Frontend / Backend Integration (API)</li>
                                <li style={{ fontSize: 11 }}>Deployment of Frontend Web App in Server</li>
                            </ul>,
                        },
                        {
                            key: 2,
                            label: <div className="tab-item">
                                <p style={{ paddingLeft: 2, paddingRight: 2 }}>Technologies</p>
                            </div>,
                            children: <ul>
                                <li style={{ fontSize: 11 }}>ReactJS</li>
                                <li style={{ fontSize: 11 }}>Redux</li>
                                <li style={{ fontSize: 11 }}>Javascript</li>
                                <li style={{ fontSize: 11 }}>CSS</li>
                                <li style={{ fontSize: 11 }}>SASS</li>
                                <li style={{ fontSize: 11 }}>HTML5</li>
                                <li style={{ fontSize: 11 }}>ANTD</li>
                                <li style={{ fontSize: 11 }}>Laravel</li>
                                <li style={{ fontSize: 11 }}>MYSQL</li>
                                <li style={{ fontSize: 11 }}>GitHub</li>
                                <li style={{ fontSize: 11 }}>Amazon Server</li>
                            </ul>,
                        },
                    ]} />
                </Panel>
                <Panel header={<h1 style={{ fontSize: 14, margin: 0, textAlign: 'left', marginBottom: 2 }}>ChatMatrix</h1>} key="2">
                    <p style={{ fontSize: 12, margin: 0, marginBottom: 1 }}>Developed an Order Management System with a Messenger Chatbot, That SME's can upload their products into management portal and publish it to the web store, So that users can buy their products using their messengers through a web or mobile application. </p>
                    <Tabs defaultActiveKey={1} tabBarStyle={{ border: 'none' }} items={[
                        {
                            key: 1,
                            label: <div className="tab-item">
                                <p style={{ paddingLeft: 2, paddingRight: 2 }}>Responsibilities</p>
                            </div>,
                            children: <ul>
                                <li style={{ fontSize: 11 }}>Convert UI/UX prototype into actual code in WEB and MOBILE</li>
                                <li style={{ fontSize: 11 }}>Make sure all the pages are responsive.</li>
                                <li style={{ fontSize: 11 }}>Producing, maintaining and modifying websites and user interfaces.</li>
                                <li style={{ fontSize: 11 }}>Fixing any website issues or bugs that arise.</li>
                                <li style={{ fontSize: 11 }}>Create and Implement Database Schema</li>
                                <li style={{ fontSize: 11 }}>Frontend / Backend Integration (API)</li>
                                <li style={{ fontSize: 11 }}>Payment Integrations</li>
                                <li style={{ fontSize: 11 }}>Deployment of Frontend / Backend Web App in Servers</li>
                                <li style={{ fontSize: 11 }}>Execute DevOps Tasks (DNS, S3, Route53, EC2, SSL, Servers)</li>
                            </ul>,
                        },
                        {
                            key: 2,
                            label: <div className="tab-item">
                                <p style={{ paddingLeft: 2, paddingRight: 2 }}>Technologies</p>
                            </div>,
                            children: <ul>
                                <li style={{ fontSize: 11 }}>ReactJS</li>
                                <li style={{ fontSize: 11 }}>React Native</li>
                                <li style={{ fontSize: 11 }}>Redux</li>
                                <li style={{ fontSize: 11 }}>Javascript</li>
                                <li style={{ fontSize: 11 }}>PHP</li>
                                <li style={{ fontSize: 11 }}>CSS</li>
                                <li style={{ fontSize: 11 }}>SASS</li>
                                <li style={{ fontSize: 11 }}>HTML5</li>
                                <li style={{ fontSize: 11 }}>ANTD</li>
                                <li style={{ fontSize: 11 }}>Laravel</li>
                                <li style={{ fontSize: 11 }}>MYSQL</li>
                                <li style={{ fontSize: 11 }}>GitLab</li>
                                <li style={{ fontSize: 11 }}>Amazon Server</li>
                                <li style={{ fontSize: 11 }}>Paymongo Payment</li>
                            </ul>,
                        },
                    ]} />
                </Panel>
            </Collapse>
        </div >
    )
}

export default ContentTwo