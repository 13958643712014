import { Tabs, Collapse } from 'antd'

const { Panel } = Collapse;
const ContentOne = () => {
    return (
        <div>
            <Collapse bordered={false} defaultActiveKey={['1', '2']} style={{ background: 'transparent', color: 'white' }}>
                <Panel header={<h1 style={{ fontSize: 14, margin: 0, textAlign: 'left', marginBottom: 2 }}>Bugden Allen Graham Lawyers</h1>} key="1">
                    <p style={{ fontSize: 12, margin: 0, marginBottom: 1 }}>Developed a system for a Law Firm which provides a debt recovery for outstanding strata levies, the technology we are using are VueJs and C#.net core.</p>
                    <Tabs defaultActiveKey={1} items={[
                        {
                            key: 1,
                            label: <div className="tab-item">
                                <p style={{ paddingLeft: 2, paddingRight: 2 }}>Responsibilities</p>
                            </div>,
                            children: <ul>
                                <li style={{ fontSize: 11 }}>Convert UI/UX prototype into actual code in WEB and MOBILE.</li>
                                <li style={{ fontSize: 11 }}>Make sure all the pages are responsive.</li>
                                <li style={{ fontSize: 11 }}>Producing, maintaining and modifying websites and user interfaces.</li>
                                <li style={{ fontSize: 11 }}>Fixing any website issues or bugs that arise.</li>
                                <li style={{ fontSize: 11 }}>Frontend / Backend Integration (API)</li>
                                <li style={{ fontSize: 11 }}>Deployment of Frontend / Backend Web App in Servers</li>
                            </ul>,
                        },
                        {
                            key: 2,
                            label: <div className="tab-item">
                                <p style={{ paddingLeft: 2, paddingRight: 2 }}>Technologies</p>
                            </div>,
                            children: <ul>
                                <li style={{ fontSize: 11 }}>VueJS</li>
                                <li style={{ fontSize: 11 }}>Javascript</li>
                                <li style={{ fontSize: 11 }}>CSS</li>
                                <li style={{ fontSize: 11 }}>SASS</li>
                                <li style={{ fontSize: 11 }}>HTML5</li>
                                <li style={{ fontSize: 11 }}>Bootstrap</li>
                                <li style={{ fontSize: 11 }}>C#/.Net</li>
                                <li style={{ fontSize: 11 }}>MSSQL</li>
                                <li style={{ fontSize: 11 }}>Azure Server</li>
                                <li style={{ fontSize: 11 }}>Azure DevOps</li>
                            </ul>,
                        },
                    ]} />
                </Panel>
                <Panel header={<h1 style={{ fontSize: 14, margin: 0, textAlign: 'left', marginBottom: 2 }}>Twintool Solution Enterprise / CPMI</h1>} key="2">
                    <p style={{ fontSize: 12, margin: 0, marginBottom: 1 }}>Developed a HRIS Accounting System, Real Estate Inventory Sales Web/App System and Real Estate Management Web/App System for Residential Buildings contains access to online services and payments using ReactJs/Laravel/NodeJS.</p>
                    <Tabs defaultActiveKey={1} tabBarStyle={{ border: 'none' }} items={[
                        {
                            key: 1,
                            label: <div className="tab-item">
                                <p style={{ paddingLeft: 2, paddingRight: 2 }}>Responsibilities</p>
                            </div>,
                            children: <ul>
                                <li style={{ fontSize: 11 }}>Convert UI/UX prototype into actual code in WEB and MOBILE</li>
                                <li style={{ fontSize: 11 }}>Make sure all the pages are responsive.</li>
                                <li style={{ fontSize: 11 }}>Producing, maintaining and modifying websites and user interfaces.</li>
                                <li style={{ fontSize: 11 }}>Fixing any website issues or bugs that arise.</li>
                                <li style={{ fontSize: 11 }}>Create and Implement Database Schema</li>
                                <li style={{ fontSize: 11 }}>Frontend / Backend Integration (API)</li>
                                <li style={{ fontSize: 11 }}>Payment Integrations</li>
                                <li style={{ fontSize: 11 }}>Deployment of Frontend / Backend Web App in Servers</li>
                                <li style={{ fontSize: 11 }}>Deployment of Android version in Google Play</li>
                                <li style={{ fontSize: 11 }}>Deployment of IOS version in Appstore</li>
                                <li style={{ fontSize: 11 }}>Execute DevOps Tasks (DNS, S3, Route53, EC2, SSL, Servers)</li>
                            </ul>,
                        },
                        {
                            key: 2,
                            label: <div className="tab-item">
                                <p style={{ paddingLeft: 2, paddingRight: 2 }}>Technologies</p>
                            </div>,
                            children: <ul>
                                <li style={{ fontSize: 11 }}>ReactJS</li>
                                <li style={{ fontSize: 11 }}>React Native</li>
                                <li style={{ fontSize: 11 }}>Redux</li>
                                <li style={{ fontSize: 11 }}>Javascript</li>
                                <li style={{ fontSize: 11 }}>PHP</li>
                                <li style={{ fontSize: 11 }}>CSS</li>
                                <li style={{ fontSize: 11 }}>SASS</li>
                                <li style={{ fontSize: 11 }}>HTML5</li>
                                <li style={{ fontSize: 11 }}>ANTD</li>
                                <li style={{ fontSize: 11 }}>Laravel</li>
                                <li style={{ fontSize: 11 }}>NODEJS</li>
                                <li style={{ fontSize: 11 }}>MongoDB</li>
                                <li style={{ fontSize: 11 }}>MYSQL</li>
                                <li style={{ fontSize: 11 }}>GitHub</li>
                                <li style={{ fontSize: 11 }}>Amazon Server</li>
                                <li style={{ fontSize: 11 }}>DragonPay Payment</li>
                                <li style={{ fontSize: 11 }}>AQWIRE Payment</li>
                            </ul>,
                        },
                    ]} />
                </Panel>
                <Panel header={<h1 style={{ fontSize: 14, margin: 0, textAlign: 'left', marginBottom: 2 }}>Simple.IO</h1>} key="3">
                    <p style={{ fontSize: 12, margin: 0, marginBottom: 6 }}>Developed a Video and PDF Annotations App Online using ReactJS/C#, Can Add Texts, Shapes, Images and comments to an uploaded file in the system. Developed a DAM which stores files in a blob. Developed LAM which is used to edit epub generated by InDesign in a web application.</p>
                    <Tabs defaultActiveKey={1} tabBarStyle={{ border: 'none' }} items={[
                        {
                            key: 1,
                            label: <div className="tab-item">
                                <p style={{ paddingLeft: 2, paddingRight: 2 }}>Responsibilities</p>
                            </div>,
                            children: <ul>
                                <li style={{ fontSize: 11 }}>Convert UI/UX prototype into actual code in WEB and MOBILE.</li>
                                <li style={{ fontSize: 11 }}>Make sure all the pages are responsive.</li>
                                <li style={{ fontSize: 11 }}>Producing, maintaining and modifying websites and user interfaces.</li>
                                <li style={{ fontSize: 11 }}>Fixing any website issues or bugs that arise.</li>
                                <li style={{ fontSize: 11 }}>Frontend / Backend Integration (API)</li>
                                <li style={{ fontSize: 11 }}>Deployment of Frontend Web App in Server</li>
                            </ul>,
                        },
                        {
                            key: 2,
                            label: <div className="tab-item">
                                <p style={{ paddingLeft: 2, paddingRight: 2 }}>Technologies</p>
                            </div>,
                            children: <ul>
                                <li style={{ fontSize: 11 }}>ReactJS</li>
                                <li style={{ fontSize: 11 }}>Javascript</li>
                                <li style={{ fontSize: 11 }}>CSS</li>
                                <li style={{ fontSize: 11 }}>SASS</li>
                                <li style={{ fontSize: 11 }}>HTML5</li>
                                <li style={{ fontSize: 11 }}>ANTD</li>
                                <li style={{ fontSize: 11 }}>Bootstrap</li>
                                <li style={{ fontSize: 11 }}>MaterialUI</li>
                                <li style={{ fontSize: 11 }}>C#/.Net</li>
                                <li style={{ fontSize: 11 }}>MSSQL</li>
                                <li style={{ fontSize: 11 }}>Azure Server</li>
                                <li style={{ fontSize: 11 }}>Azure DevOps</li>
                            </ul>,
                        },
                    ]} />
                </Panel>
            </Collapse>





        </div >
    )
}

export default ContentOne