import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
// Components
import Header from "../components/Header"
import Work from "../components/work"
import About from "../components/about"
import Skills from "../components/skills"
import Footer from "../components/Footer"
import MouseParticles from "react-mouse-particles";
import 'react-use-smooth-scroll/dist/index.css'
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax'

const IndexPage = () => {
  const [isBrowser, setIsBrowser] = useState(false)
  useEffect(() => {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      setIsBrowser(true)
    }
  }, [])

  return (
    <ParallaxProvider>
      <Layout>
        {/* {
          isBrowser && <MouseParticles g={0}
            num={10}
            radius={25}
            life={0.9}
            v={1.2}
            color="rgba(255, 255, 255, 0.1)"
            alpha={0.7}
            level={300} />
        } */}
        <Header ></Header>

        {/* <Parallax speed={25}> */}
        <About></About>

        {/* </Parallax> */}

        <Skills></Skills>
        <Work></Work>
        <Footer></Footer>
      </Layout>
    </ParallaxProvider>



  )
}
export default IndexPage
