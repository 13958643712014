import { Tabs, Collapse } from 'antd'

const { Panel } = Collapse;
const ContentFour = () => {
    return (
        <div>
            <Collapse bordered={false} defaultActiveKey={['1', '2']} style={{ background: 'transparent', color: 'white' }}>
                <Panel header={<h1 style={{ fontSize: 14, margin: 0, textAlign: 'left', marginBottom: 2 }}>National Bureau of Investigation </h1>} key="1">
                    <p style={{ fontSize: 12, margin: 0, marginBottom: 1 }}>Developed an Evidence Inventory System for forensic laboratory of National Bureau of Investigation (Cybercrime Division Forensic Laboratory). The system can save information and images of an evidence (Using vb.net, MS access).</p>
                    <Tabs defaultActiveKey={1} items={[
                        {
                            key: 1,
                            label: <div className="tab-item">
                                <p style={{ paddingLeft: 2, paddingRight: 2 }}>Responsibilities</p>
                            </div>,
                            children: <ul>
                                <li style={{ fontSize: 11 }}>Convert UI/UX prototype into actual code in WEB and MOBILE.</li>
                                <li style={{ fontSize: 11 }}>Make sure all the pages are responsive.</li>
                                <li style={{ fontSize: 11 }}>Producing, maintaining and modifying user interfaces.</li>
                                <li style={{ fontSize: 11 }}>Fixing any software issues or bugs that arise.</li>
                                <li style={{ fontSize: 11 }}>Frontend / Backend Integration (API)</li>
                            </ul>,
                        },
                        {
                            key: 2,
                            label: <div className="tab-item">
                                <p style={{ paddingLeft: 2, paddingRight: 2 }}>Technologies</p>
                            </div>,
                            children: <ul>
                                <li style={{ fontSize: 11 }}>VB.net</li>
                                <li style={{ fontSize: 11 }}>MSAccess</li>
                            </ul>,
                        },
                    ]} />
                </Panel>
            </Collapse>
        </div >
    )
}

export default ContentFour