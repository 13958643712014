import 'antd/dist/antd.css';
import "react-image-gallery/styles/scss/image-gallery.scss";
import IndexPage from './pages/index'
function App() {
  return (
    <div className="App">
      <IndexPage />
    </div>
  );
}

export default App;
