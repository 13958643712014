import { Tabs, Collapse } from 'antd'

const { Panel } = Collapse;
const ContentThree = () => {
    return (
        <div>
            <Collapse bordered={false} defaultActiveKey={['1', '2']} style={{ background: 'transparent', color: 'white' }}>
                <Panel header={<h1 style={{ fontSize: 14, margin: 0, textAlign: 'left', marginBottom: 2 }}>Acudeen Technologies Inc. </h1>} key="1">
                    <p style={{ fontSize: 12, margin: 0, marginBottom: 1 }}>Developed a system with buy/sell marketplace of invoices etc. We are using REACT JS, Redux, Node, C#, Python, Bootstrap and HTML5.</p>
                    <Tabs defaultActiveKey={1} items={[
                        {
                            key: 1,
                            label: <div className="tab-item">
                                <p style={{ paddingLeft: 2, paddingRight: 2 }}>Responsibilities</p>
                            </div>,
                            children: <ul>
                                <li style={{ fontSize: 11 }}>Convert UI/UX prototype into actual code in WEB and MOBILE.</li>
                                <li style={{ fontSize: 11 }}>Make sure all the pages are responsive.</li>
                                <li style={{ fontSize: 11 }}>Producing, maintaining and modifying websites and user interfaces.</li>
                                <li style={{ fontSize: 11 }}>Fixing any website issues or bugs that arise.</li>
                                <li style={{ fontSize: 11 }}>Frontend / Backend Integration (API)</li>
                                <li style={{ fontSize: 11 }}>Deployment of Frontend Web App in Server</li>
                            </ul>,
                        },
                        {
                            key: 2,
                            label: <div className="tab-item">
                                <p style={{ paddingLeft: 2, paddingRight: 2 }}>Technologies</p>
                            </div>,
                            children: <ul>
                                <li style={{ fontSize: 11 }}>ReactJS</li>
                                <li style={{ fontSize: 11 }}>Redux</li>
                                <li style={{ fontSize: 11 }}>Javascript</li>
                                <li style={{ fontSize: 11 }}>CSS</li>
                                <li style={{ fontSize: 11 }}>SASS</li>
                                <li style={{ fontSize: 11 }}>HTML5</li>
                                <li style={{ fontSize: 11 }}>Bootstrap</li>
                                <li style={{ fontSize: 11 }}>Python</li>
                                <li style={{ fontSize: 11 }}>Node</li>
                                <li style={{ fontSize: 11 }}>MySQL</li>
                                <li style={{ fontSize: 11 }}>MongoDB</li>
                                <li style={{ fontSize: 11 }}>Node</li>
                                <li style={{ fontSize: 11 }}>Azure</li>
                                <li style={{ fontSize: 11 }}>Bitbucket</li>
                            </ul>,
                        },
                    ]} />
                </Panel>
            </Collapse>
        </div >
    )
}

export default ContentThree